<template>
    <a-card title="" :bordered="false">
        <div v-if="loading" class="init-loading">
            <a-spin size="large" tip="加载中，请稍后" />
        </div>
        <template v-else>
            <a-row class="article-list" align="bottom" v-if="currentList.length">
                <a-col class="article-list__col" :span="12" v-for="item in currentList" :key="item.id">
                    <a :href="'/help/article/'+ item.id +'.html'" target="_blank" class="article-list__link">{{item.title}}</a>
                </a-col>
                <a-col class="article-list__col" v-if="currentList.length % 2 == 1"></a-col>
            </a-row>
            <a-empty v-else class="data-empty" description="没有找到数据哦~">
            </a-empty>
            <a-pagination
                v-if="pagination.total > pagination.limit"
                v-model="pagination.current"
                :total="pagination.total"
                :default-page-size="pagination.limit"
                show-less-items
                @change="pageChange" />
        </template>
    </a-card>
</template>

<script>
export default {
    data() {
        return {
            loading: true,
            articleList: {}, // 稿件列表集合
            // 分页配置
            pagination: {
                current: 1,
                limit: 20,
                total: 0
            }
        }
    },
    computed: {
        // 当前分页列表
        currentList() {
            let thisPage = this.pagination.current;
            let thisData = this.articleList['page_' + thisPage];
            return thisData ? thisData : [];
        },
    },
    created() {
        this.getArticleList()
    },
    methods: {
        // 获取文章列表
        getArticleList() {
            this.loading = true;
            const page = this.pagination.current;
            this.$api.articleGetlist({
                page,
                limit: this.pagination.limit,
            }).then(res => {
                if (res.status) {
                    let listData = {};
                    listData['page_' + page] = res.data.list;
                    this.articleList = Object.assign({}, this.articleList, listData);
                    this.pagination.total = res.data.count;
                }
            }).finally(() => {
                this.loading = false;
            })
        },
        // 翻页
        pageChange(current) {
            if (!this.articleList['page_' + current]) {
                this.listloading = true;
                this.getArticleList();
            }
        },
    }
}
</script>

<style lang="less" scoped>
// 空数据
.data-empty {
    padding: 180px 0;
}

// 进入加载状态
.init-loading {
    text-align: center;
    padding: 100px 0;
    height: 320px;
}
// 文章列表
.article-list{
    border-top: 1px solid #f1f1f1;
    border-left: 1px solid #f1f1f1;
    &__col{
        border-right: 1px solid #f1f1f1;
        border-bottom: 1px solid #f1f1f1;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        height: 64px;
    }
    &__link{
        display: inline-block;
        width: 100%;
        font-size: 16px;
        line-height: 26px;
        max-height: 52px;
        color: #666;
        overflow: hidden;
        &:hover{
            color: @color-default;
            text-decoration: underline;
            cursor: pointer;
        }
    }
}
</style>